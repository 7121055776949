.notification {
  background: $primary-blue;
  color: $white;
}

.notification__inner {
  padding: $global-spacing-medium;
  position: relative;
}

.notification__link {
  color: $white;
  border-bottom: 2px solid $interactive-cyan;

  &:hover {
    color: darken($white, 20%);
  }
}

.notification__title {
  font-weight: 700;
  display: block;
  margin-bottom: $global-spacing-small;
}

.notification .close-button {
  color: $white;
}

.notification .close-button:hover {
  color: darken($white, 20%);
}

.notification__message {
    font-size: rem-calc(14);
}
