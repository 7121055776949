.anc-bar {
  background: $primary-grey-tint;
  padding: $global-spacing;

  @include breakpoint (medium) {
    padding: $global-spacing 0;
  }
}

.anc-bar__item {
  display: flex;
  flex-direction: column;

  @include breakpoint (medium) {
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
}

.anc-bar__message {
  @include breakpoint (medium) {
    margin-bottom: 0;
  }
}

.anc-bar__title {
  color: $primary-blue;
  font-weight: 700;
  display: block; 
  padding-right: $global-spacing;
  margin-bottom: $global-spacing-small;

  @include breakpoint (medium) {
      display: inline-block;
      margin-bottom: 0;
  }
}

.anc-bar__link .ui-hover {
  border-bottom: $anchor-border;
}