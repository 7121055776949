$card-width: rem-calc(330);

.content-group--subgroup .featured-group  {
  margin-bottom: 0;

  @include breakpoint(large) {
    margin-bottom: $global-spacing-xl;
  }
}

.featured-group__items {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  overflow-x: scroll;

  li {
    &:first-child {
      margin-left: 0;
    }
  }
}

.featured-group__item .card,
.feature-group__item .card-course {
  box-shadow: none;

  &:hover {
    box-shadow: $box-shadow;
  }
}

.featured-group__item {
  margin-left: $global-spacing;
}
