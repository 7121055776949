.main-footer {
  padding: rem-calc(60) 0;
  text-align: center;

  @include breakpoint(large) {
    text-align: left;
  }
}

.main-footer--primary {
  background: $primary-color;
  color: $white;
}

.main-footer--primary .title {
  color: inherit;
}

.main-footer--primary a {
  color: inherit;
  text-decoration: none;
  border-bottom: 0;

  &:hover {
    border-bottom: 2px solid $white;
  }
}

.main-footer__logo {
  width: rem-calc(230);
  margin-bottom: $global-spacing-large;
}

