.card-purchase {
  border: 2px solid $primary-grey-tint;
}

.card-purchase .card-section,
.card-purchase .card-divider {
  @include breakpoint (medium) {
    padding: $global-spacing $global-spacing-large;
  }
}

.card-purchase__header {
  background: transparent;
  border-bottom: 2px solid $primary-grey-tint;
}

.card-purchase__pricing {
  display: flex;
  width: 100%;
}

.card-purchase__price {
  flex-basis: 50%;
}

.card-purchase_currency,
.card-purchase_figure,
.card-purchase__tax {
  font-size: rem-calc(28);
  font-weight: 600;
}

.card-purchase__status {
  @extend .h2;
  display: block;
}

.card-purchase__status,
.card-purchase__tax {
  font-size: rem-calc(14);
  font-weight: 600;
}

.card-purchase--primary {
  background: $primary-blue;
  color: $white;
  border: 0;
}

.card-purchase--primary .card-purchase__price {
  color: $white;
}

.card-purchase--primary .card-purchase__header {
  border: 0;
}

.card-purchase__currency,
.card-purchase__figure {
  margin: 0;
  padding: 0;
}

.card-purchase__currency {
  margin-right: -6px;
}
