.stat-group__content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  p {
    font-size: rem-calc(18);
  }
}

.stat-group--bg {
  background: linear-gradient($white, 50%, $primary-grey-tint 50%);
}

.stat-group__first,
.stat-group__second {
  padding-right: $global-spacing;
}

.stat-group-carousel {
  background: $primary-grey-tint;
  padding-top: $global-spacing-large;
}
