.link-list-group {
  padding-left: $global-spacing;
  padding-right: $global-spacing;
}

.link-list-group__container {
  padding-top: $global-spacing;

  @include breakpoint (large) {
    padding-top: $global-spacing-large;
  }
}

.link-list-group .link-list {
  padding: $global-spacing 0;
  margin-bottom: 0;

  @include breakpoint (large) {
    padding: $global-spacing $global-spacing-large;
    border-right: $keyline-border;
  }
}

.link-list-group__title {
  @include breakpoint (large) {
    text-align: center;
  }
}
