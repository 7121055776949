.card-job {
  @include breakpoint (large) {
    width: 274px;
  }
}

.card-job .card-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-job__category {
  font-weight: 600;
  color: $interactive-cyan;
  margin-bottom: $header-margin-bottom;
}

.card-job__title {
  font-weight: 600;
}

.card-job__meta {
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  margin-bottom: $global-spacing-small;

  span {
    font-size: rem-calc(14);
    font-weight: 600;
    color: $primary-grey;
  }
}

.card-job__body {
  margin-bottom: $global-spacing-xl;
}

.card-job__link {
  border-bottom: 0;
  font-weight: 500;

  @include breakpoint (medium) {
    align-self: flex-end;
  }
}
