.card-contact-form {
  border: 0;
  padding: $global-spacing;
}

.card-contact-form__subtitle,
.card-contact-form__title,
.card-contact-form__label{
  font-weight: 600;
  margin-bottom: $global-spacing-small;
}

.card-contact-form__form {
  margin: 0;
  padding: 0;
}

.card-contact-form__email {
  margin: 0 0 $global-spacing-small 0;
  width: 100%;
}

.card-contact-form__submit {
  width: 100%;
  padding: $global-spacing 0;
}
