.link-list {
  list-style-type: none;
  margin-bottom: $global-spacing-large;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.link-list__title {
  font-weight: 600;
  color: inherit;
}

.link-list__items {
  list-style-type: none;
  margin: $global-spacing-small 0;

  li {
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.link-list--footer .link-list__item a {
  text-decoration: none;
  border-bottom: 0;

  &:hover {
    border-bottom: 2px solid $white;
  }
}

.link-list__item {
  margin: $global-spacing 0;

  a {
    border-bottom: $anchor-border;
    font-size: rem-calc(18);

    @include breakpoint (large) {
      font-size: rem-calc(16);
    }
  }
}

.link-list__icon {
  margin-bottom: $global-spacing-small;
}

.link-list--row {
  & .link-list__items {
    display: flex;
    flex-direction: column;

    @include breakpoint (medium) {
      flex-direction: row;
      justify-content: space-between;
    }
  }
}
