.icon {
  width: 24px;
  height: 24px;
  fill: $interactive-cyan;
  display: block;
  margin: 0 auto;

  & + span {
    display: inline-block;
    margin: 0.5em 0.5em 0;
    font-size: rem-calc(10);

    @include breakpoint(medium) {
      font-size: 1em;
    }
  }

  &--arrow-down {
    transform: rotate(90deg);
  }

  &--arrow-up {
    transform: rotate(-90deg);
  }

  &--arrow-left {
    transform: rotate(180deg);
  }

  &--close {
    transform: rotate(45deg);
  }
}

.icon--inline {
  display: inline-block;
}

.filter-icon {
  &--arrow-down {
    transform: rotate(90deg);
  }

  &--arrow-up {
    transform: rotate(-90deg);
  }

  &--arrow-left {
    transform: rotate(180deg);
  }
}
