.article-page__title {
  padding-bottom: $global-spacing-small;
}

.article-content__meta-wrapper {
  padding-bottom: $global-spacing;
}

.article-content__meta {
  display: flex;
  flex-basis: 50%;
  justify-content: space-between;
  width: 330px;
  padding: $global-spacing 0;

  span {
    font-size: rem-calc(14);
    font-weight: 600;
  }
}

.article-content__meta .icon {
  margin-right: $global-spacing-small;

  + span {
    margin: 0;
  }
}

.article-content__flags {
  color: $primary-color;
  flex-basis: 50%;
  border-right: 1px solid $black;
}

.article-content__tags {
  color: $primary-grey;
  flex-basis: 50%;
  text-align: right;
}

.article-content__sidebar .card-promotion__body {
  width: auto;
}

.component-title__sidebar {
  font-weight: 600;
}

.article-content__lead-image {
  margin-bottom: $global-spacing;
}

.article-content__main {
  padding-bottom: $global-spacing;

  .lead {
    font-weight: 600;
    padding-bottom: $global-spacing;
  }

  .blockquote-dark {
    font-size: rem-calc(20);
    border-left: 2px solid $primary-grey;
    padding: rem-calc(5) rem-calc(20) rem-calc(15);
    margin-top: $global-spacing-large;
    margin-left: 30%;
    margin-bottom: 0;
  }

  .blockquote-dark-footer {
    font-size: rem-calc(16);
    font-weight: 600;
    border-left: 2px solid $primary-grey;
    padding: rem-calc(10) rem-calc(20) 0;
    margin-left: 30%;
    margin-bottom: $global-spacing-large;
  }
}

.article-content__sidebar .card-promotion__details {
  border: 1px solid $primary-grey-tint;
  border-top: 0;
}
