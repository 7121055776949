.promo-slider__title {
  margin-bottom: $global-spacing;

  @include breakpoint (medium) {
    margin-bottom: $global-spacing-xl;
    width: 90%;
  }
}

.promo-slider {
  padding-top: $global-spacing-large;

  @include breakpoint (medium) {
    border-right: $keyline-border;
    padding-right: $global-spacing-large;
  }
}

.promo-slider__wrapper {
  margin-bottom: $global-spacing-large;
}

.promo-slider__pagination {
  display: flex;
  align-items: flex-end;
}

.promo-slider__control {
  position: relative;
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background-color: transparent;
  }

  &:focus {
    outline: $interactive-cyan-tint;
  }
}

.promo-slider__count {
  padding: $global-spacing;
}
