.card-article-social-share {
  border: 0;
  padding: $global-spacing 0;
}

.card-article-social-share__title {
  font-size: rem-calc(16);
  margin-bottom: $global-spacing;
  font-weight: 600;
}

.card-article-social-share__links {
  margin-bottom: $global-spacing-small;
}
