.form--checkout {
  [type='text'], 
  [type='password'], 
  [type='date'], 
  [type='datetime'], 
  [type='datetime-local'], 
  [type='month'], 
  [type='week'], 
  [type='email'], 
  [type='number'], 
  [type='search'], 
  [type='tel'], 
  [type='time'], 
  [type='url'], 
  [type='color'], {
    width: 100%;
  }
}

.form--checkout .field--postcode {
  width: rem-calc(150);
}
