.res-tabs__group-toolbar {
  padding-top: $global-spacing-large;

  @include breakpoint (medium) {
    padding-top: $global-spacing-xl;
  }
}

.res-tabs__group-toolbar,
.res-tabs__wrapper {
  background-color: $primary-grey-tint;
}

.res-tabs {
  &.tabs {
    border: none;
    background-color: transparent;
    display: flex;
  }

  &.member-grading-tabs {
    a {
      padding-right: 0;
    }
  }
}

.res-tabs__tabs-content {
  margin-bottom: $global-spacing-large;

  &.tabs-content {
    border: none;
  }
}

.res-tabs__item {
  a {
    height: 100%;
  }

  &.is-active a[aria-selected='true'] {
    background-color: $white;
  }

  a[aria-selected='false'] {
    background-color: transparent;
  }
}

.res-tabs-item__title {
  display: block;
  font-size: rem-calc(18);
  font-weight: 600;
  line-height: rem-calc(26);
}

.res-tabs-item__subtitle {
  display: block;
  font-size: rem-calc(14);
  line-height: rem-calc(18);
  width: auto;
  max-width: 200px;
}

.res-tabs__main-content {
  margin-top: $global-spacing-large;

  &.content__member-grading {
    .content-lead {
      font-size: rem-calc(16);
      font-weight: 600;
      line-height: rem-calc(22);
      color: $primary-blue;
    }

    .res-tabs__main-content--title,
    .res-tabs__main-content--subtitle {
      margin-bottom: $global-spacing-small;
    }
    .res-tabs__main-content--subtitle {
      margin-top: $global-spacing-large;
    }
  }
}

.res-tabs__main-content--title {
  font-size: rem-calc(22);
  line-height: rem-calc(26);
  margin-bottom: $global-spacing;
}

.res-tabs__downloads--wrapper,
.res-tabs__contact--wrapper,
.membership-fees {
  border: none;
}

.membership-fees {
  background-color: $primary-blue;
  margin-top: $global-spacing-large;
}

.res-tabs__downloads--wrapper {
  margin-top: $global-spacing-xl;

  .card-section {
    padding-top: 0;
  }
}

.res-tabs__contact--wrapper {
  background-color: $interactive-cyan-tint;
}

.res-tabs__downloads--title,
.res-tabs__contact--title,
.res-tabs__main-content--subtitle {
  font-size: rem-calc(18);
  font-weight: 600;
  line-height: rem-calc(26);
  margin-bottom: $global-spacing;
}

.membership-fees__title {
  font-size: rem-calc(18);
  font-weight: bold;
  line-height: rem-calc(22);
  margin-top: $global-spacing-small;
  margin-bottom: $global-spacing;
  color: $white;
}

.res-tabs__downloads--list {
  list-style: none;
  padding: 0;
  margin: 0;
}

.res-tabs__downloads--icon {
  vertical-align: top;
  margin-top: rem-calc(10);
}

.res-tabs__downloads--list-item--title {
  display: inline-block;
  width: auto;
  max-width: 260px;
}

.res-tabs__card-text {
  font-size: rem-calc(18);
  line-height: rem-calc(28);

  &.res-tabs__downloads--list-item {
    font-size: rem-calc(18);
    font-weight: 500;
    line-height: rem-calc(24);
  }
}

.membership-fees__residents,
.membership-fees__international {
  color: $white;
}

.membership-fees__price {
  font-size: rem-calc(30);
  font-weight: 500;
  line-height: rem-calc(30);
}

.membership-fees__price--label {
  font-size: rem-calc(14);
  font-weight: bold;
  line-height: rem-calc(17);
  width: 128px;
}

.membership-fees__price--label-small {
  font-size: rem-calc(16);
  font-weight: 400;
  line-height: rem-calc(22);
}

.membership-fees__link {
  margin-top: $global-spacing;
}

.membership-fees__link--button {
  color: $white;
  width: 100%;
}
