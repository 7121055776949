.order-item {
  padding: $global-spacing-large 0;
  border-bottom: 1px solid $primary-grey-tint;
}

.order-actions {
  margin: $global-spacing-small 0 0;
  display: flex;
  list-style: none;
  flex-direction: column;

  @include breakpoint (medium) {
    flex-direction: row;
  }
}

.order-item__title {
  margin-bottom: $global-spacing-small;
}

.order-item__meta {
  font-size: rem-calc(14);
  color: $primary-grey;
}

.order-actions__item {
  margin-right: $global-spacing-large;
  margin-bottom: $global-spacing;

  &:last-child {
    margin-bottom: 0;
  }
}

.order-actions__link {
  display: flex;
  align-items: flex-end;

  span {
    border-bottom: $anchor-border;
    font-size: rem-calc(14);
  }
}

.order-actions__link .icon {
  margin: 0;

  @include breakpoint (medium) {
    margin: 0 auto;
  }
}

.account-sidebar {
  padding: $global-spacing;
  max-width: rem-calc(410);
  margin: 0 auto;
}

.account-sidebar--primary {
    background: $primary-blue;
}

.account-sidebar--primary .account-sidebar__link,
.account-sidebar--primary .title,
.account-sidebar--primary .active-course-list__status,
.account-sidebar--primary .cpd-chart__item {
    color: $white;
}

.sidebar-section {
  padding: $global-spacing 0;
  border-top: 1px solid $white;
}

.sidebar-section .title {
  margin-bottom: $global-spacing;

}

.sidebar-section .account-search,
.sidebar-section .account-submit {
    width: 100%;
}

.sidebar-section .account-submit {
  @include breakpoint (medium) {
    width: $default-button-width;
  }
}

.actions-list {
  list-style: none;
  margin: $global-spacing 0;
  display: flex;
  flex-direction: column;

  &--grid {
    flex-direction: row;
  }
}

.actions-list__item {
  display: flex;
  justify-content: space-between;
  margin-bottom: $global-spacing;

  &:last-child {
    margin-bottom: 0;
  }
}

.actions-list__link {
  span {
    border-bottom: $anchor-border;
  }
}

.actions-list--grid .actions-list__item {
  flex-direction: column;

  @include breakpoint (medium) {
    padding-right: 5em;
    align-items: center;
    flex-direction: row;
  }
}

.actions-list .title {
  margin-bottom: 0;
}

.cpd-chart__item {
  padding: $global-spacing;
  text-align: center;
  border-bottom: 1px solid $white;
  font-size: rem-calc(18);

  @include breakpoint (medium) {
    border-right: 1px solid $white;
    border-bottom: 0;
  }

  &:last-child {
    border: 0;
  }
}

.cpd-chart__status {
  display: block;
  font-size: rem-calc(20);
}

.cpd-chart__impact {
  font-size: 150%;
}

.actions-list__title {
  font-size: rem-calc(18);
}