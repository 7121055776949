$story-gradient: linear-gradient(to bottom, $white 8%, $interactive-cyan-tint 8% 100%);
$story-gradient-large: linear-gradient(to bottom, $white 8%, $interactive-cyan-tint 8% 92%, $white 8%);
$story-gradient-grey: linear-gradient(to bottom, $white 8%, $primary-grey-tint 8% 92%, $white 8%);

.facts-group {
  background: $story-gradient;
}

.card-facts-list__items {
  list-style-type: none;
  margin: $global-spacing-small 0;

  &:last-child {
    margin-bottom: 0;
  }
}

.cell.card-facts__teaser {
  padding-left: 0;
}
