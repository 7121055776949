/* Component specific variables */

$input-group-padding: rem-calc(12) rem-calc(20);

.search-bar__group {
  background: $white;
  padding: $global-spacing-small;
  display: flex;
  flex-direction: column;

  @include breakpoint(medium) {
    flex-direction: row;
  }
}

.search-bar__wrapper {
  display: flex;
  flex-direction: row;

  @include breakpoint (medium) {
    width: 100%;
  }
}

.search-bar__field {
  padding: $input-group-padding;
  height: auto;
  border-color: $white;
  box-shadow: none;
  margin-left: $global-spacing-small;
  margin-right: $global-spacing-small;
  width: 95%;

  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.search-bar .button {
  margin-bottom: 0;
  width: 100%;

  @include breakpoint (medium) {
    width: auto;
  }
}

.search-bar .icon {
  align-self: center;
  margin: 0 rem-calc(8);
  margin-top: -15px;

  @include breakpoint (medium) {
    margin-top: 0;
  }
}

.search-bar__input-wrapper {
  display: flex;
}

.search-bar__options {
  display: flex;
  margin-top: $global-spacing;
}

.search-options .search-options__label {
  color: inherit;
  margin-bottom: $global-spacing-small;

  @include breakpoint (medium) {
    margin-bottom: 0;
  }
}

.search-bar--dark .search-options__label,
.hero--primary .search-options__label {
  color: $white;
}

.search-bar__title {
  font-size: rem-calc(26);

  @include breakpoint (medium) {
    font-size: rem-calc(30);
  }
}