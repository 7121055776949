.button,
.button-group .button,
.button-group.stacked-for-small .button {
  font-size: rem-calc(16);
  font-weight: 700;
  color: $white;
  height: rem-calc(60);
  width: rem-calc(280);
}

.button--secondary,
.button-group .button--secondary,
.button-group .secondary,
.button-group.stacked-for-small .button--secondary  {
  border: 2px solid $primary-grey-light;
  background: $white;
  color: $primary-color;

  &:hover {
    background-color: $primary-grey-tint;
    color: $primary-color;
  }
}

.button--icon {
  text-align: left;
}

.button--icon .icon {
  float: right;
  margin-top: -4px;
}

.button--small {
  height: unset;
  padding: $button-small-padding;

  @include breakpoint(medium) {
    min-width: rem-calc(176);
  }
}

.button-collection {
  
  .button {
    @include breakpoint(medium) {
      margin-left: $global-spacing-small;

      &:first-child {
        margin-left: 0;
      }
    }
  }
}
