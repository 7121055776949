.contact-map {
  position: relative;
}

.contact-map__wrapper {
  max-width: 100%;
  overflow: hidden;
}

.contact-card {
  padding: $global-spacing-large $global-spacing;
  position: absolute;
  top: 25%;
  box-shadow: $box-shadow;
  max-width: rem-calc(410);
}

.contact-card__address {
  padding: $global-spacing 0;
}