.card-article-author {
  border: 0;
  padding: $global-spacing 0;
}

.card-article-author__title {
  font-size: rem-calc(20);
  margin-bottom: $global-spacing-small;
  font-weight: 600;
}

.card-article-author__image {
  display: inline-block;
  float: left;
}

.card-article-author .card-section {
  display: inline-block;
  float: left;
  padding: 0 $global-spacing;
}
