.basket-items td,
.basket-items th {
  font-size: rem-calc(20);
  color: $primary-blue;
  padding: 16px 14px;
}

.basket-items th {
  font-weight: 500;
}

.basket-items-footer {
  border: 1px solid $primary-grey-tint;
  border-left: 0;
  border-right: 0;
  background: $white;

  td {
    padding: 24px 14px;
  }

  .basket-items__label {
    text-align: right;
    font-weight: normal;
  }

  .basket-price {
    font-size: rem-calc(24);
    font-weight: normal;
  }

  .basket-price--members {
    font-weight: 700;
  }
}

.basket-price {
  span {
    text-transform: uppercase;
    font-size: rem-calc(14);
  }
}

.basket-price--member {
  font-weight: 700;
}

.row--vat {
  border-top: 1px solid $primary-grey-tint;

  td {
    color: $primary-grey;
    font-size: 16px;
  }
  
  .basket-price--members {
    font-weight: normal;
  }

}

.row--total {
  .basket-price {
    font-size: rem-calc(28);
  }
}

.basket-items .basket-items__action {
  font-size: rem-calc(16);

  a {
    font-weight: 500;
    margin-top: 2px;
  }
}