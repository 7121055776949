.ui-accordion__title {
  background: $interactive-cyan-tint;
  font-size: rem-calc(18);
  color: $primary-blue;
  display: flex;
  align-items: center;

  &:hover,
  &:focus {
    background: $interactive-cyan-tint;
  }
}

.ui-accordion__title::before {
  font-size: rem-calc(40);
  color: $interactive-cyan;
  top: unset;
  margin-top: 0;
}

.ui-accordian__content .title {
  display: inline-block;
}