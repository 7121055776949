.stat-card {
  padding: 3em 0 3em 3em;
  text-align: left;
  box-shadow: $box-shadow;
  border: 0;

  @include breakpoint (medium) {
    padding: 3em;
  }

  p {
    font-size: rem-calc(18);
  }
}

.stat-card .stat-card__menu {
  display: flex;
  flex-flow: nowrap;
  overflow: auto;

  @include breakpoint (medium) {
    flex-flow: wrap;
    justify-content: space-around;
  }
}

.stat-card .stat-card__item {
  margin: 0;
  padding: 0 2em 0 0;

  @include breakpoint (medium) {
    padding: 0 3em;
    margin-top: 1em;
    margin-bottom: 1em;
  }
}

.stat-group .stat-group__right {
  padding-right: 0;

  @include breakpoint (medium) {
    padding-right: .9375rem;
  }
}
