// sass-lint:disable force-pseudo-nesting no-qualifying-elements

/* Common wrappers and layout helpers */

.ui-group {
  margin-top: $global-spacing;
}

.component {
  padding: $global-padding;
}

/* Links */

.link-with-icon {
  display: inline-block;
  padding-right: 2.5em;
  margin-right: $global-spacing-small;

  &--arrow-right {
    background: url('../../assets/img/icons/arrow-right.svg') right no-repeat;
  }
}

/* Typography helpers */

.group-heading--inline {
  display: inline-flex;
  margin-bottom: 0;
}

/* Background utility classes */

.bg--interactive-cyan-tint {
  background-color: $interactive-cyan-tint;
}

.bg--primary-grey-tint {
  background-color: $primary-grey-tint;
}

/* Toggle hamburger button */

.hamburger {
  cursor: pointer;
  margin: 0;
  text-align: center;
}

.hamburger__box {
  width: 24px;
  height: 24px;
  position: relative;
  margin: 0 auto;
  padding: 2px;
}

.hamburger__inner,
.hamburger__inner:before,
.hamburger__inner:after {
  width: 20px;
  height: 2px;
  transition-property: transform;
  position: absolute;
  display: block;
  border-radius: 4px;
  background: $interactive-cyan;
}

.hamburger__inner {
  top: 58%;
  margin-top: -2px;
}

.hamburger__inner:before {
  top: -6px;
  transition: top .1s ease-in .25s,opacity .1s ease-in;
}

.hamburger__inner:after,
.hamburger__inner:before {
  content: '';
}

.hamburger__inner:after {
  bottom: -6px;
  transition: bottom .1s ease-in .25s,transform .22s cubic-bezier(.55,.055,.675,.19);
}

.nav-open .hamburger__inner,
.hamburger:active .hamburger__inner {
  transition-delay: .12s;
  transform: rotate(225deg);
  transition-timing-function: cubic-bezier(.215,.61,.355,1);
}

.nav-open .hamburger__inner:before,
.hamburger:active .ui-hamburger__inner:before {
  top: 0;
  transition: top .1s ease-out,opacity .1s ease-out .12s;
  opacity: 0;
}

.nav-open .hamburger__inner:after,
.hamburger:active .hamburger__inner:after {
  bottom: 0;
  transform: rotate(-90deg);
}

.js-off-canvas-overlay {
  top: rem-calc(81);
}

.hamburger__box + span {
  display: inline-block;
  margin: 0.5em 0.5em 0;
  font-size: rem-calc(10);

  @include breakpoint(medium) {
    font-size: 1em;
  }
}

.breadcrumbs .breadcrumbs__link,
.breadcrumbs a {
  border: 0;

  &:hover {
    border-bottom: 2px solid $interactive-cyan;
    text-decoration: none;
  }
}

.breadcrumbs .breadcrumbs__item {
  text-transform: initial;
}

.form-wrapper {
  margin: $global-spacing 0;
}

a.ui-hover {
  border-bottom: $anchor-border;
}

.twitter-typeahead {
  width: 100%;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: none;
  background-color: rgba(10, 10, 10, 0.45);
  overflow-y: auto;

  &--active {
    display: block;
  }
}

.flex-reverse {
  .flex-second {
    @include breakpoint (medium) {
      order: -1;
    }
  }
}

.help-text {
  font-size: rem-calc(14);
}

.inner-content__wrapper {
  margin-bottom: $global-spacing-large;
}
