.card-course {
  width: rem-calc(275);
  box-shadow: $box-shadow;
  height: rem-calc(330);
  justify-content: space-between;
  position: relative;

  &:hover {
    box-shadow: 0 0 0 0 0.25rem;
  }
}

.card-course__category {
  font-weight: 600;
  color: $interactive-cyan;
  margin-bottom: $header-margin-bottom;
}

.card-course__title {
  margin-bottom: $global-spacing-small;
  font-weight: 600;
}

.card-course__title a {
  text-decoration: none;

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.card-course__item span {
  font-size: rem-calc(14);
  color: $primary-grey;
  font-weight: 600;
  margin: 0 0 0 rem-calc(5);
  vertical-align: bottom;
}

.card-course__inner {
  display: flex;
  padding: $global-spacing;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.card-course__price {
  font-size: rem-calc(18);
  color: $primary-color;
  flex-basis: 50%;
  font-weight: 600;
  padding: 10px 0 10px 20px;
  border-left: 1px solid $white;
}

.card-course__price:first-child {
  padding-right: $global-spacing-small;
  padding-left: 0;
  border: 0;
}

.card-course__status {
  display: block;
  font-size: 14px;
  color: $black;
  font-weight: normal;
}

.card-course__pricing {
  display: flex;
  padding: 0 $global-spacing;
}

.card-course__tax {
  font-size: rem-calc(12);
}

.card-course__icons {
  margin-bottom: 0;

  li {
    &:first-child {
      margin-bottom: $global-spacing-small;
    }
  }
}

.card-course--qual .card-course__pricing {
  padding: $global-spacing;
}
