.callout--primary {
  background: $primary-blue;
  color: $white;
}

.callout--primary h3 {
  color: $white;
}

.callout__cta {
  display: flex;
}

.callout__cta .button {
  margin: 0 auto;
  width: 100%;
}
