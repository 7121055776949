.login .cell:first-child {
  border-bottom: $keyline-border;
  border-width: 3px;

  @include breakpoint (large) {
    border-right: $keyline-border;
    border-bottom: 0;
    border-width: 3px;
  }
}

.login-section {
  padding: 3em 0;
  
  @include breakpoint (large) {
    padding: 3em;
  }
}

.login-section--first {
  padding: 0em 0 3em 0;
  
  @include breakpoint (large) {
    padding: 3em 6em 3em 0em;
  }
}

.login-section--second {
  padding: 3em 0 0 0;

  @include breakpoint (large) {
    padding: 3em 0em 3em 6em;
  }
}

.login-section--expandable {
  display: none;
}

.login-section--expandable.expanded {
  display: block;
}