$story-gradient: linear-gradient(to bottom, $white 8%, $interactive-cyan-tint 8% 100%);
$story-gradient-large: linear-gradient(to bottom, $white 8%, $interactive-cyan-tint 8% 92%, $white 8%);
$story-gradient-grey: linear-gradient(to bottom, $white 8%, $primary-grey-tint 8% 92%, $white 8%);

.card-story {
  padding-bottom: $global-spacing-large;
  background: $story-gradient;

  @include breakpoint (large) {
    padding: 0;
    background: $story-gradient-large;
  }
}

.card-story--grey {
  background: $story-gradient-grey;
}

.card-story__links {
  display: flex;
  flex-direction: column;

  @include breakpoint (medium) {
    flex-direction: row;
  }

  > * {
    padding: $global-spacing-small 0;
  }
}

.card-story__link {
  margin-right: $global-spacing-large;
}

.card-story__image {
  padding: 0 0 $global-spacing-large;

  @include breakpoint (large) {
    padding: 0;
  }
}
