.member-grade .membership-fees {
  margin: 0;
}

.grade-info {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 100%;
}

.grade-info .title {
  display: block;
}

.grade-info__intro {
  margin-bottom: $global-spacing;
}