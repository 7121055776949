$filter-bar-title-width: 210px;
$filter-icon-size: rem-calc(24);
$filter-item-height: rem-calc(45);

.filter-bar {
  margin-bottom: $global-spacing;

  @include breakpoint (medium) {
    @include xy-gutters($gutter-type: padding, $gutter-position: right);

    padding-left: 0;
  }
}

.filter-bar__title {
  padding: $global-spacing-small $global-spacing;
  background: url('../../assets/img/icons/down-chevron.svg') $primary-grey-tint right no-repeat;
  background-position-x: 95%;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  min-width: $filter-bar-title-width;

  @include breakpoint (medium) {
    background: none;
    cursor: initial;
  }

  h3 {
    margin-bottom: 0;
    font-size: rem-calc(16);

    @include breakpoint (medium) {
      font-size: 1.25rem;
      font-weight: 500;
    }
  }

  img {
    margin-right: $global-spacing;
    width: $filter-icon-size;
    height: $filter-icon-size;

    @include breakpoint (medium) {
      display: none;
    }
  }
}

.filter-bar__wrapper {
  overflow: hidden;
}

.filter-bar-items {
  height: 0;

  @include breakpoint (medium) {
    height: auto;
  }

  &--expanded {
    height: auto;
  }
}

.filter-box {
  padding: $global-spacing;
  background: $primary-grey-tint;
  margin-bottom: $global-spacing;
}

.filter-box__item {
  padding-bottom: $global-spacing;

  &:last-child {
    padding-bottom: 0;
  }

  label {
    margin-left: 0;
  }
}

.filter-box__title {
  margin-bottom: $global-spacing;
  font-weight: 700;
}

/* Filter control */

.filter-control,
.filter-control__group {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
}

.filter-control__select {
  height: $filter-item-height;
  padding: $global-spacing-small $global-spacing;
  border: 0;
  background-color: $primary-grey-tint;
  padding-right: 40px;
  margin-bottom: 0;

  &:focus {
    background-color: $primary-grey-tint;
  }

  @include breakpoint (medium) {
    min-width: $filter-bar-title-width;
  }
}

.control-icon {
  height: $filter-item-height;
  background-color: $primary-grey-tint;
  padding: $global-spacing-small 0 $global-spacing-small $global-spacing;
}

/* Search form */

.search-form__inner {
  padding: $global-spacing-large 0;
}

.filter-results .card-course {
  margin-bottom: 0;
  width: 100%;
  box-shadow: none;

  @include breakpoint (medium) {
    width: rem-calc(275);
  }
}

.filter-bar .button {
  width: 100%;
}
