.lead-grid__copy {
  p {
    @include breakpoint (medium) { 
      font-size: rem-calc(20);
    }
  }
}

.lead-grid__title {
  margin-bottom: $global-spacing;

  @include breakpoint (medium) {
    margin-bottom: $global-spacing-small;
  }
}
