$promo-card-max-width: rem-calc(570);
$promo-title-group-max-width: rem-calc(330);
$promo-card-body-width: rem-calc(370);

.card-promotion {
  border: 0;
  max-width: $promo-card-max-width;
  position: relative;
}

.card-promo__image-wrapper {
  position: relative;
  color: $white;
  background: $primary-grey;

  &::after {
    display: block;
    position: relative;
    background-image: $image-linear-gradient;
    margin-top: -200px;
    height: 200px;
    width: 100%;
    content: '';
  }
}

.card-promotion__title-group {
  max-width: $promo-title-group-max-width;
  position: absolute;
  bottom: $global-spacing;
  left: $global-spacing;
  z-index: 1;
}

.card-promotion__category {
  font-weight: 600;
}

.card-promotion__title {
  font-size: rem-calc(20);
  font-weight: 500;
  margin-bottom: 0;
  color: $white;
}

.card-promotion__image {
  object-fit: cover;
}

.card-promotion__details {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.card-promo-group .card-promotion__body {
  @include breakpoint (large) {
    width: rem-calc(370);
  }
}

.card-promotion__body {
  .ui-hover {
    &::after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      right: 0;
      bottom: 0;
    }
  }
}
