// sass-lint:disable no-qualifying-elements

// Component specific variables
$menu-link-spacing: rem-calc(10);
$menu-link-padding: rem-calc(5) rem-calc(30);
$menu-button-padding: rem-calc(22) rem-calc(44);
$submenu-shadow: 0 1px 3px 1px rgba(214,207,214,0.54);
$menu-active-shadow: 0 -3px 4px -2px rgba(214,207,214,0.54);
$nav-search-height: 3.75rem;

.nav {
  display: flex;
  color: $black;
  justify-content: flex-end;
}

.nav-menu {
  align-items: center;

  @include breakpoint(medium) {
    padding: $global-spacing-small 0;
  }

  a {
    border-bottom: 0;
  }
}

.nav-bg {
  @include breakpoint(medium) {
    background: $primary-grey-tint;
  }

  &--blue {
    background: $interactive-cyan-tint;
  }
}

.nav--account {
  justify-content: flex-end;
}

.nav--account .icon {
  @include breakpoint(medium) {
    display: inline-block;
  }
}

.nav--account .nav-menu__link {
  padding: $global-spacing-small;
  font-size: rem-calc(14);
  font-weight: 500;

  @include breakpoint(medium) {
    padding: $menu-link-padding;
    border-right: 2px solid $primary-blue-tint;
  }
}

.nav--account .nav-menu__item {
  position: relative;

  @include breakpoint(medium) {
    border-right: $keyline-border;
  }

  &:last-child {
    .nav-menu__link {
      padding-right: 0;

      @include breakpoint (medium) {
        padding: $menu-link-padding;
      }
    }
  }
}

.nav--primary {
  margin-top: $global-spacing;

  @include breakpoint (large) {
    min-height: 60px;
  }
}

.nav--primary .nav-menu {
  @include breakpoint (large) {
    padding: 0;
  }
}

.nav--primary .nav-menu li {
  @include breakpoint (large) {
    height: 100%;
    align-items: center;
    margin: 0;
    margin-right: $menu-link-spacing;
  }
}

.nav--primary .nav-menu__link {
  margin-right: $menu-link-spacing;
  padding: $global-spacing-small $menu-link-spacing;

  @include breakpoint (large) {
    display: flex;
    align-items: center;
    height: 100%;
    margin: 0;
  }
}

.submenu .nav-menu__link span {
  border-bottom: 2px solid transparent;
  padding-bottom: $anchor-padding;
  line-height: 1.5;
}

.nav--primary .submenu {
  border: 0;
  background: $interactive-cyan-tint;
}

.nav-menu__link {
  color: $black;
  font-size: rem-calc(16);
  font-weight: 600;
}

.nav-menu__button {
  cursor: pointer;
}

.nav-menu--off-canvas {
  padding: 0;
}

.nav-menu--off-canvas .nav-menu__item {
  padding: 0;
  width: 100%;
}

.nav-menu--off-canvas .button {
  padding: $button-padding;
  margin: rem-calc(20) rem-calc(24);
}

.nav-menu--off-canvas .nav-menu__link {
  padding: rem-calc(24);
  border-bottom: $keyline-border;
}

.nav-menu--off-canvas .is-active > a {
  background: $primary-grey-tint;
  color: $black;
}

.nav-menu--off-canvas li.is-active { 
  background: $primary-grey-tint;
}

.nav-menu--off-canvas .nav-menu__link.is-active span {
  border-bottom: $anchor-border;
}

.nav-menu--off-canvas .submenu {
  padding: 0;
}

.nav-menu--off-canvas .submenu-toggle {
  top: rem-calc(8);
}

.nav-menu--off-canvas .is-accordion-submenu a {
  padding: $global-spacing;
}

.nav .button {
  margin-bottom: 0;
  width: auto;
  padding: $menu-button-padding;
}

.off-canvas--nav {
  top: rem-calc(81);
}

// sass-lint:disable nesting-depth
// Needed to override the foundation styles

.dropdown {
  &.menu {
    li {
      &.nav-menu__item {
        > a {
          display: block;
          padding-right: 2.5em;
          background: url('../assets/img/icons/down-chevron.svg') right no-repeat;
          background-position-x: 95%;
          background-position-y: 45%;
        }
      }
    }

    > li {
      &.is-active {
        > a {
          background: url('../assets/img/icons/down-chevron.svg') $interactive-cyan-tint right no-repeat;
          background-position-x: 95%;
          background-position-y: 45%;
          box-shadow: $menu-active-shadow;

          > span {
            border-bottom: $anchor-border;
            padding-bottom: $anchor-padding;
          }
        }
      }
    }
  }
}

.dropdown .menu li a.is-active span,
.dropdown.menu li.nav-menu__item--active > a > span {
  border-bottom: 2px solid $interactive-cyan;
}

.dropdown.menu li.nav-menu__item > a.is-active,
.dropdown.menu li.nav-menu__item--active > a
 {
  background: url('../assets/img/icons/down-chevron.svg') $interactive-cyan-tint right no-repeat;
  background-position-x: 95%;
  background-position-y: 45%;
  box-shadow: $menu-active-shadow;
}

.dropdown.menu li.nav-menu__item > a.is-active span {
  border-bottom: $anchor-border; 
  padding-bottom: $anchor-padding;
}

.dropdown.menu .submenu .nav-menu__link {
  background: none;
}

.is-submenu-item a {
  font-weight: 600;
}

.submenu {
  padding: 20px 0;
}

.dropdown  .is-dropdown-submenu .nav-menu__link {
  padding: 0 20px 20px;
  width: 100%;
}

.dropdown .is-dropdown-submenu li:last-child .nav-menu__link {
  padding-bottom: 0;
}

.nav--secondary .nav-menu {
  @include breakpoint (large) {
    justify-content: space-around;
    width: 100%;
  }
}

.nav--secondary {
  border-bottom: 1px solid $white;
  flex-direction: column;
}

.nav--secondary .menu {
  width: 100%;
}

.nav--secondary .button-group {
  width: $global-width;
  margin: 0 auto;
  flex-wrap: wrap;
}

.nav--secondary .button-group .nav-menu__item {
  margin-right: $global-spacing-small;
}

.nav--secondary .button-group .button {
  width: auto;
}

.nav-search {
  display: none;
  height: $nav-search-height;
}

.nav-search .search-bar__group .button {
  padding: 0;
}

.nav-search .search-bar__group {
  position: absolute;
  top: 81px;
  width: 100%;
  left: 0;
  flex-direction: row;

  @include breakpoint (large) {
    min-width: 600px;
    padding-left: $global-spacing;
    position: relative;
    top: initial;
    left: initial;
    width: auto;
  }
}

.nav-search .search-bar__field {
  margin-bottom: 0;

  @include breakpoint (large) {
    margin: 0 $global-spacing;
  }
}

.nav-search .search-bar__wrapper {
  align-items: center;
  width: 100%;
}

.nav--active {
  .nav-search {
    display: flex;
    flex-direction: row;
  }

  .nav-menu__wrapper {
    display: none;
  }
}

.nav-menu__wrapper {
  display: flex;
  flex-direction: row;
}

.nav-search__submit-mob {
  padding: 0 $global-spacing;

  @include breakpoint (large) {
    display: none;
  }
}

.nav-search__submit {
  display: none;

  @include breakpoint (large) {
    display: block;
  }
}

.nav-toggle {
  background-color: $interactive-cyan-tint;
  margin: 0;
  width: 100%;
  display: flex;
}

.nav-toggle__link {
  padding: $global-spacing;
  width: 100%;
  display: flex;
  justify-content: space-between;
  color: $black;
}

.nav-toggle__link .icon {
  margin: 0;
}

.nav--secondary .nav-menu__item {
  width: 100%;

  @include breakpoint (medium) {
    width: auto;
  }
}

.nav--secondary .nav-menu__link {
  padding: $global-spacing;
  border-bottom: 2px solid $white;

  @include breakpoint (medium) {
    border-bottom: 0;
    padding: $global-spacing-small;
  }

  &:hover {
    span {
      border-bottom: $anchor-border;
      padding-bottom: $anchor-padding;
    }
  }
}

.nav--secondary .is-active span {
  border-bottom: $anchor-border;
  padding-bottom: $anchor-padding;
}

.js-basket-count {
  position: absolute;
  top: 10px;
  left: 12px;
  background-color: $interactive-cyan;
  color: $white;
  border-radius: 60%;
  height: rem-calc(16);
  width: rem-calc(16);
  margin: 0;
  box-shadow: $box-shadow;
  line-height: 1.4;
  text-align: center;
  font-size: rem-calc(12);

  @include breakpoint (medium) {
    top: 6px;
    left: 23px;
  }
}

.login-menu--active .login-links {
  display: flex;
  flex-direction: row;
  height: $nav-search-height;
}

.login-menu--active .nav-menu__wrapper {
  display: initial;
}

.login-menu--active .nav-search,
.login-menu--active .nav-menu--main,
.login-menu--active .nav-menu__wrapper .button {
  display: none;
}

.login-links {
  background: $primary-grey-tint;
  display: none;
}

.menu.align-right li.login-links__item {
  justify-content: flex-start;
  font-size: rem-calc(14);

  &:last-child {
    border: none;
  }

  span {
    border-bottom: 2px solid transparent;
    padding-bottom: 2px;
  }

  &:hover {
    span {
      border-bottom: 2px solid $interactive-cyan;
      padding-bottom: 2px;
    }
  }
}

.icon.svg-basket {
  vertical-align: middle;
  fill: $primary-grey;
}

.basket-populated .icon.svg-basket {
  fill: $interactive-cyan;
}

.js .js-basket-count {
  display: none;
}

.js .basket-populated .js-basket-count {
  display: block;
}
