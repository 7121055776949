a {
  text-decoration: none;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}

blockquote {
  font-size: rem-calc(20);
}
