.key-details__item {
  display: flex;
  border-bottom: 1px solid $primary-grey-tint;
  padding: 15px 0;
  flex-direction: row;

  &:last-child {
    border-bottom: 0;
  }
}

.key-details__title {
  width: 50%;
  font-size: rem-calc(18);
  font-weight: 600;
  color: $primary-blue;
  display: inline-block;

  .key-details__title-note {
    width: 90%;
    font-size: rem-calc(14);
    font-weight: normal;
    color: $black;
    display: inline-flex;
  }
}

.key-details__body {
  width: 50%;
  display: inline-block;
}

.key-details__provider {
  width: 40%;
}
