.main-header {
  border-bottom: $keyline-border;
  background: $white;
  position: sticky;
  top: 0;
  left: 0;
  padding: $global-spacing-small 0;
  z-index: 5;

  @include breakpoint(large) {
    position: relative;
    overflow: initial;
    padding: 0;
  }
}

.main-header__top-bar {
  background-color: transparent;

  & ul {
    background: transparent;

    @include breakpoint(medium) {
      background: $light-blue;
    }
  }
}

.main-header__logo {
  width: 3em;

  @include breakpoint(medium) {
    width: auto;
  }
}
