// Card Teaser
$article-image-size: rem-calc(120);

.article-teaser {
  height: 100%;
  border: 0;
  position: relative;
}

.article-teaser__title {
  font-weight: 600;
  margin-bottom: $global-spacing-small;
}

.article-teaser__link {
  text-decoration: none;
  border-bottom: 0;
  line-height: 1.5;

  &:hover {
    border-bottom: $anchor-border;
  }

  &::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
  }
}

.article-teaser__meta {
  display: flex;

  span {
    font-size: rem-calc(14);
    font-weight: 600;
  }
}

.article-teaser__meta .icon {
  margin-right: $global-spacing-small;

  + span {
    margin: 0;
  }
}

.article-teaser__flags {
  color: $primary-color;
  border-right: 1px solid $black;
  padding-right: $global-spacing-small;
  margin-right: $global-spacing-small;
}

.article-teaser__tags {
  color: $primary-grey;
}

.article-teaser--stacked {
  flex-direction: row;

  .article-teaser__image {
    max-height: $article-image-size;
    object-fit: initial;
  }
}

.article-teaser--stacked .card-section {
  flex: initial;
  padding: $global-spacing-small $global-spacing; 

  @include breakpoint (medium) {
    display: flex;
    flex-direction: column;
    padding: $global-spacing-small $global-spacing;
  }
}

.article-teaser--stacked .article-teaser__meta {
  flex-direction: column;

  .article-teaser__tags {
    text-align: left;
  }

  .article-teaser__flags {
    border: 0;
  }

  div {
    margin-bottom: $global-spacing-small;
  }
}
