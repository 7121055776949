.card-navigation {
  @include breakpoint (large) {
    width: 370px;
  }
}

.card-navigation .card-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.card-navigation__title {
  font-weight: 600;
}

.card-navigation__link {
  border-bottom: 0;
  font-weight: 500;
  
  @include breakpoint (medium) {
    align-self: flex-end;
  }
}

.card-figure {
  height: rem-calc(210);
  width: rem-calc(370);
  background: $primary-blue;
  color: $white;
  display: flex;
  flex-direction: row;
  align-items: middle;
  justify-content: center;
}

.card-figure__logo {
  fill: $white;
  width: 25%;
  margin-right: $global-spacing;
}

.card-figure__title {
  align-self: center;
  font-size: 30px;
  line-height: 1.2;
  flex-basis: 40%;
}

.nav-card-group {
  background: $primary-grey-tint;
}
