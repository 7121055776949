.course-details__wrapper {
  p {
    font-size: rem-calc(18);
  }
}

.course-details__main-content {
  h3 {
    font-size: rem-calc(24);

    @include breakpoint (medium) {
      font-size: rem-calc(30);
    }
  }
}

.course-details__sidebar {
  .card-article-social-share {
    padding: 0;
  }
}

.course-details-info {
  padding: $global-spacing;
  background-color: $interactive-cyan-tint;
}

/* Can't use BEM here as HTML is returned in field */

.course-details-info ul {
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  list-style: none;
}

.course-details-info li {
  margin-bottom: $global-spacing;
  padding-right: $global-spacing;
  display: inherit;

  @include breakpoint (medium) {
    flex-basis: 50%;
  }
}

.course-details-info li::before {
  content:  url('../img/icons/circle-tick.svg');
  width: 40px;
  height: 28px;
  vertical-align: top;
  display: inline-block;
  padding-right: $global-spacing;
}
