/* Component specific variables */
.group-toolbar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: baseline;
  margin-bottom: $global-spacing;

  &--collapse {
    margin-bottom: 0;
  }

  &--full {
    width: 100%;
    @include breakpoint (medium) {
      width: auto;
    }
  }

  @include breakpoint (large) {
    flex-direction: row;
  }
}

.group-toolbar--full .group-toolbar__pagination {
  width: 100%;

  @include breakpoint (large) {
    width: auto;
  }
}

.group-toolbar--with-logo {
  h2 {
    display: flex;
    flex-direction: column;
    font-size: rem-calc(20);

    @include breakpoint (medium) {
      flex-direction: row;
      align-items: flex-end;
    }
  }

  .group-toolbar__image {
    margin-bottom: $global-spacing;

    @include breakpoint (medium) {
      margin-right: $global-spacing;
      margin-bottom: 0;
    }
  }
}
