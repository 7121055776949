.global-spacing {
  padding: $global-spacing;
}

.global-spacing--small {
  padding: $global-spacing-small;
}

.global-spacing--large {
  padding: $global-spacing-large;
}

.global-spacing--xl {
  padding: $global-spacing-xl;
}

.content-group {
  padding: $global-spacing 0;

  @include breakpoint (large) {
    padding: $global-spacing-xl 0;
  }

  &--subgroup {
    padding: $global-spacing-small 0;
  }
}

.main-content-wrapper {
  @extend .grid-container;
  margin-top: $global-spacing-large;
  margin-bottom: 3em;

  @include breakpoint (large) {
    margin-top: $global-spacing-xl;
  }

  section {
    &:first-child {
      padding-top: 0;
    }
  }
}

.content-wrapper {
  max-width: $global-width;
  margin: 0 auto;
}
