
.help-group--bg {
  background: $interactive-cyan-tint;
  padding-top: $global-spacing;
  padding-bottom: $global-spacing-xl;
}

.help-group__container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  margin-top: $global-spacing-small;
}

.help-group__body {
  font-size: rem-calc(18);
}

.help-group__phone-title {
  font-weight: 400;
  margin-bottom: $global-spacing-small;
}
