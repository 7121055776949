/* Component specific variables */

$hero-width: 40em;
$hero-padding: 3em $global-spacing;
$hero-item-spacing: 3em;
$hero-search-bar-spacing: 2em;

.hero {
  padding: $hero-padding;
  background-repeat: no-repeat;
  background-size: inherit;
  background-position: 70% 0%;
  position: relative;
  overflow: hidden;

  @include breakpoint (large) {
    background-position: 100% 0%;
  }
}

.hero--boxed {
  max-width: 1440px;
  margin: 0 auto;
}

.hero__title {
  padding: $global-spacing-small 0 $hero-item-spacing;

  @include breakpoint(medium) {
    padding: $global-spacing 0;
  }
}

.hero__subtitle,
.hero .title {
  margin-bottom: $global-spacing;
  color: $white;
}

.hero--primary {
  color: $white;

  .hero__title {
    color: $white;
  }
}

.hero__main .button {
  @include breakpoint(medium) {
    margin-bottom: 0;
  }
}

.hero__bottom {
  margin-top: $hero-search-bar-spacing;
}

.hero--primary .twitter-typeahead {
  width: 100%;
}

.hero__banner {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  z-index: -1;
  background: $primary-blue;
  width: 100%;

  @include breakpoint (medium) {
    width: auto;
  }
}

.hero__banner::after {
  display: block;
  position: relative;
  background-image: linear-gradient(to bottom, rgba(0,64,81,0) 0, #004051 100%);
  margin-top: -500px;
  height: 500px;
  width: 100%;
  content: '';

  @include breakpoint (medium) {
    height: 200px;
    margin-top: -200px;
  }
}

.hero__wrapper {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -2;
  height: 100%;

  @include breakpoint (medium) {
    background: $primary-blue;
    height: 550px;
  }
}