/* Component specific variables */

.card-video__copy {
  @include breakpoint (medium) {
    order: 0;
  }
}

.card-video__media {
  @include breakpoint (medium) {
    order: 1;
  }
}

