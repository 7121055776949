// sass-lint:disable force-pseudo-nesting, no-qualifying-elements, force-attribute-nesting

/* Custom radio buttons */

$inside-radio-size: rem-calc(12);
$checkbox-size: rem-calc(22);
$inside-checkbox-size: rem-calc(20);
$select-icon-position: 95%;

[type='text'], 
[type='password'], 
[type='date'], 
[type='datetime'], 
[type='datetime-local'], 
[type='month'], 
[type='week'], 
[type='email'], 
[type='number'], 
[type='search'], 
[type='tel'], 
[type='time'], 
[type='url'], 
[type='color'], 
textarea {
  @include breakpoint (medium) {
    width: auto;
  }
}

.form {
  margin: $global-spacing-large 0;
}

.user-login-form {
  margin: $global-spacing-large 0;
}

.form-element__wrapper {
  margin-bottom: $global-spacing;
}

.field-wrapper,
.field-group__wrapper {
  margin-bottom: $global-spacing-small;
  display: flex;
  flex-direction: column;
}

.field-wrapper--large {
  margin-bottom: $global-spacing-large;
}

input[type='radio'] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: rem-calc(30);
  margin-right: $global-spacing;

  &:before {
    content: '';
    display: block;
    width: rem-calc(20);
    height: rem-calc(20);
    margin-right: $global-spacing;
    position: absolute;
    top: 0;
    left: 0;
    border: 2px solid $interactive-cyan;
    background-color: $white;
    border-radius: 50%;
    top: 3px;
  }
}

input[type='radio'] {
  display: none;

  &:checked + label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 4px;
    width: $inside-radio-size;
    height: $inside-radio-size;
    border-radius: 50%;
    background: $interactive-cyan;
    top: 7px;
  }
}

/* Custom checkbox */

input[type='checkbox'] + label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  margin-right: $global-spacing;

  &:before {
    content: '';
    display: block;
    width: $checkbox-size;
    height: $checkbox-size;
    margin-right: $global-spacing;
    position: absolute;
    top: -3px;
    left: 0;
    border: 2px solid $interactive-cyan;
    background-color: $white;
    top: 2px;
  }
}

input[type='checkbox'] {
  display: none;

  &:checked + label:after {
    content: '✔';
    font-size: $inside-checkbox-size;
    line-height: $inside-checkbox-size;
    color: $interactive-cyan;
    display: block;
    position: absolute;
    top: 0;
    left: 4px;
    width: $inside-checkbox-size;
    height: $inside-checkbox-size;
    border-radius: 3px;
    top: 5px;
  }
}

select {
  appearance: none;
  background: url('../../assets/img/icons/down-chevron.svg') right no-repeat;
  background-position-x: $select-icon-position;

  &:focus {
    background: url('../../assets/img/icons/up-chevron.svg') right no-repeat;
    background-position-x: $select-icon-position;
  }
}

.pagination {
  padding: $global-spacing;

  a {
    border-bottom: 0;
  }
}

.pagination .current a {
  color: $white;

  &:hover {
    background: $primary-blue;
  }
}

label {
  margin-bottom: rem-calc(8);
  font-weight: 500;
}

.form__steps {
  display: block;
  font-weight: 600;
  margin-bottom: $global-spacing;
  color: $primary-blue
}

.form__intro {
  margin-bottom: $global-spacing-large;
}

.field__legend {
  font-weight: 600;
  margin-bottom: $global-spacing;
  color: $primary-blue
}

.field--postcode {
  width: rem-calc(150);
}

.user-details-section {
  margin-bottom: 80px;
}

.error-callout {
  padding: $global-spacing 0;
  color: $alert-red;
}

.is-invalid-input:not(:focus) {
  background-color: $white;
}

.callout--form-help {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: $global-spacing 0;

  @include breakpoint (medium) {
    max-width: rem-calc(320);
    width: auto;
  }

  .callout__icon {
    flex-basis: 25%;
    margin-right: $global-spacing;
  }
}

.checkout-action-bar {
  display: flex;
  flex-direction: column;

 @include breakpoint (medium) {
  flex-direction: row;
  justify-content: flex-end;
 }

 .button {
  width: 100%;

  @include breakpoint (medium) {
   margin: $global-spacing;
   margin-left: 0;
   width: $default-button-width;

   &:last-child {
     margin-right: 0;
   }
  }
 }
}
