.title--xxl {
  @extend .h1;
}

.title--xl {
  @extend .h2;
}

.title--l {
  @extend .h3;
}

.title--m {
  @extend .h4;
}

.title--s {
  @extend .h5;
}

.title--xs {
  @extend .h6;
}

.number {
  font-size: rem-calc(60);
  color: $primary-color;
  font-family: $body-font-family;
  font-weight: 300;
  line-height: 1.2em;
}

.h1-desktop-white {
  font-size: 50px;
  line-height: 1.1;
  color: $white;
}

.h1-desktop-dark {
  font-size: 50px;
  color: $primary-color;
}

.h1-mobile-white {
  font-size: 35px;
  line-height: 1.14;
  color: $white;
}

.h1-mobile-dark {
  font-size: 35px;
  line-height: 1.14;
  color: $primary-color;
}

.h2-desktop-white {
  font-size: 30px;
  font-weight: 500;
  line-height: 1;
  color: $white;
}

.h2-desktop-dark {
  font-size: 30px;
  line-height: 1.17;
  color: $primary-color;
}

.h3-dark {
  font-size: 22px;
  line-height: 1.18;
  color: $primary-color;
}

.h2-mobile-white {
  font-size: 22px;
  line-height: 1.36;
  color: $white;
}

.h2-mobile-dark {
  font-size: 22px;
  line-height: 1.36;
  color: $primary-color;
}

.h3-white {
  font-size: 20px;
  font-weight: 500;
  line-height: 1.3;
  color: $white;
}

.blockquote-dark {
  font-size: 20px;
  line-height: 1.5;
  color: $primary-color;
}

.p-large-dark-bold {
  font-size: 18px;
  line-height: 1.56;
  color: $black;
}

.h4-white {
  font-size: 18px;
  font-weight: 600;
  color: $white;
}

.h4-dark {
  font-size: 18px;
  font-weight: 600;
  line-height: 1.44;
  color: $primary-color;
}

.p-large-dark {
  font-size: 18px;
  line-height: 1.56;
  color: $black;
}

.caption-large-dark {
  font-size: 18px;
  font-weight: 700;
  line-height: 1.56;
  color: $primary-color;
}

.caption-dark {
  font-size: 14px;
  font-weight: 700;
  line-height: 1.43;
  color: $primary-color;
}

.tags-white {
  font-size: 14px;
  font-weight: 600;
  color: $white;
}

.tags-dark {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.43;
  color: $primary-grey;
}

.h5-white {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  color: $white;
}

.h5-dark {
  font-size: 14px;
  font-weight: 600;
  line-height: 1.57;
  color: $primary-color;
}

.supporting-text-white {
  font-family: ProximaNova;
  font-size: 14px;
  line-height: 1.29;
  color: $white;
}

.supporting-text-dark {
  font-size: 14px;
  line-height: 1.29;
  color: $black;
}

.breadcrumb-dark {
  font-size: 12px;
  color: $primary-color;
}
