.card-enquiries-details {
  border: 0;
  padding: $global-spacing;
}

.card-enquiries-details__title {
  font-size: rem-calc(20);
  margin-bottom: $global-spacing;
}

.card-enquiries-details__name {
  margin-bottom: $global-spacing-small;
  font-weight: 600;
}

.card-enquiries-details__position,
.card-enquiries-details__email{
  margin-bottom: $global-spacing;
  font-weight: 600;
}
